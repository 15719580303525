import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { ProgressSpinner } from "primereact/progressspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faShoePrints } from "@fortawesome/free-solid-svg-icons";
import api from "../api/api";
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    activeMember: 0,
    totalStepCount: 0,
    totalMinutes: 0,
  });
  const [topPerformers, setTopPerformers] = useState([]);
  const [newUserData, setNewUserData] = useState([]);
  const [weekWiseData, setWeekWiseData] = useState([]);
  const UserId = sessionStorage.getItem("UserID");
  const Password = sessionStorage.getItem("Password");
  const Email = sessionStorage.getItem("Email");

  // const data = [
  //   { name: "Mon", Time: 600, Steps: 5000 },
  //   { name: "Tue", Time: 700, Steps: 5500 },
  //   { name: "Wed", Time: 80, Steps: 6000 },
  //   { name: "Thu", Time: 900, Steps: 6500 },
  //   { name: "Fri", Time: 1000, Steps: 7000 },
  //   { name: "Sat", Time: 1200, Steps: 7500 },
  //   { name: "Sun", Time: 1400, Steps: 8000 },
  // ];

  // const medals = ["🥇", "🥈", "🥉"];
  // const medals = [
  //   { color: "#FFD700" }, // Gold
  //   { color: "#C0C0C0" }, // Silver
  //   { color: "#CD7F32" }, // Bronze
  // ];
  const medals = [
    { src: "/Gold Medal.png", alt: "Gold Medal" }, // Gold Medal Image
    { src: "/Silver Medal.png", alt: "Silver Medal" }, // Silver Medal Image
    { src: "/Bronze Medal.png", alt: "Bronze Medal" }, // Bronze Medal Image
  ];
  const getCardsData = async () => {
    try {
      setLoading(true);
      const requestData = {
        userid: UserId,
        password: Password,
        synceddatetime: "2023-01-24 11:57:34",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getCards(requestData);
      if (result && result.status === 200) {
        const data = result.data.dashboard_count[0];
        setDashboardData({
          activeMember: data.active_member,
          totalStepCount: data.total_step_count,
          totalMinutes: data.total_minutes,
        });
        // console.log("response success", data);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };
  const getNewUsersData = async () => {
    try {
      setLoading(true);
      const requestData = {
        userid: UserId,
        password: Password,
        synceddatetime: "2023-01-24 11:57:34",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getNewUsers(requestData);
      if (result && result.status === 200) {
        const data = result.data.dashboard_count;
        const formattedData = data
          .map((item) => ({
            name: item.month_name,
            Users: item.registrations_by_month,
            yearMonth: item.fld_year_month,
          }))
          .sort((a, b) => (a.yearMonth > b.yearMonth ? 1 : -1));

        setNewUserData(formattedData);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };
  const getTopPerformersData = async () => {
    try {
      setLoading(true);
      const requestData = {
        userid: UserId,
        password: Password,
        synceddatetime: "2023-01-24 11:57:34",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getTopPerformers(requestData);
      // console.log("response success", result.status);
      if (result && result.status === 200) {
        // console.log("response success", result.data.dashboard_count);
        setTopPerformers(result.data.dashboard_count);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };
  const getDayWiseAchievementData = async () => {
    try {
      setLoading(true);
      const requestData = {
        fld_email: Email,
        synceddatetime: "2023-01-24 11:57:34",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getDayWiseAchievement(requestData);
      console.log("response success", result);
      if (result && result.status === 200) {
        const dashboardDayWiseData = result.data.dashboard_count[0];
        // console.log("dash",dashboardDayWiseData)
        const formattedData = [
          {
            name: "Monday",
            Steps: dashboardDayWiseData.monday_count || 0,
            Time: dashboardDayWiseData.monday_time || 0,
          },
          {
            name: "Tuesday",
            Steps: dashboardDayWiseData.tuesday_count || 0,
            Time: dashboardDayWiseData.tuesday_time || 0,
          },
          {
            name: "Wednesday",
            Steps: dashboardDayWiseData.wednesday_count || 0,
            Time: dashboardDayWiseData.wednesday_time || 0,
          },
          {
            name: "Thursday",
            Steps: dashboardDayWiseData.thursdayday_count || 0,
            Time: dashboardDayWiseData.thursdayday_time || 0,
          },
          {
            name: "Friday",
            Steps: dashboardDayWiseData.friday_count || 0,
            Time: dashboardDayWiseData.friday_time || 0,
          },
          {
            name: "Saturday",
            Steps: dashboardDayWiseData.saturday_count || 0,
            Time: dashboardDayWiseData.saturday_time || 0,
          },
          {
            name: "Sunday",
            Steps: dashboardDayWiseData.sunday_count || 0,
            Time: dashboardDayWiseData.sunday_time || 0,
          },
        ];
        setWeekWiseData(formattedData);
        // console.log("response success", result.data.dashboard_count);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCardsData();
    getDayWiseAchievementData();
    getNewUsersData();
    getTopPerformersData();
  }, []);
  return (
    <div className="container">
      {loading && (
        <div className="pro-spin">
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#7265e2",
            }}
          />
        </div>
      )}
      <span className="heading-page mt-0">Dashboard</span>
      <div className="gridDashboard mt-3">
        <div className="col-4">
          <div
            className="dashboardStyle"
            style={{ backgroundColor: "#1AB0B0" }}
          >
            <div className="grid mt-0" style={{ height: "150px" }}>
              <div className="col-4 flex flex-column justify-content-center align-items-center">
                <img
                  src="/tabler_walk.png"
                  alt="logo"
                  style={{ width: "80px" }}
                />
              </div>
              <div className="col-8">
                <h1
                  className="mb-2 mt-4 text-white"
                  style={{ fontSize: "40px" }}
                >
                  {/* {dashboardData.totalStepCount} */}
                  {dashboardData.totalStepCount?.toLocaleString() || "0"}
                </h1>
                <p className="progressText mt-2">Total Steps walked</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div
            className="dashboardStyle"
            style={{ backgroundColor: "#FB7748" }}
          >
            <div className="grid mt-0" style={{ height: "150px" }}>
              <div className="col-4 flex flex-column justify-content-center align-items-center">
                <img
                  src="/tdesign_member.png"
                  alt="logo"
                  style={{ width: "80px" }}
                />
              </div>
              <div className="col-8">
                <h1
                  className="mb-2 mt-4 text-white"
                  style={{ fontSize: "40px" }}
                >
                  {dashboardData.activeMember}
                </h1>
                <p className="progressText mt-2">Active members</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div
            className="dashboardStyle"
            style={{ backgroundColor: "#8676FE" }}
          >
            <div className="grid mt-0" style={{ height: "150px" }}>
              <div className="col-4 flex flex-column justify-content-center align-items-center">
                <img
                  src="/icon-park-outline_time.png"
                  alt="logo"
                  style={{ width: "80px" }}
                />
              </div>
              <div className="col-8">
                <h1
                  className="mb-2 mt-4 text-white"
                  style={{ fontSize: "40px" }}
                >
                  {/* {dashboardData.totalMinutes?.toFixed(2) || "0"} */}
                  {/* {(dashboardData.totalMinutes ? Math.round(dashboardData.totalMinutes / 60) : "0")} */}
                  {dashboardData.totalMinutes
                    ? Math.round(
                        dashboardData.totalMinutes / 60
                      ).toLocaleString()
                    : "0"}
                </h1>
                <p className="progressText mt-2">Total hours walked</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <h2 className="graphTitle">Day wise achievement </h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={weekWiseData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            barSize={20}
          >
            <XAxis dataKey="name" stroke="#8884d8" />
            <YAxis stroke="#8884d8" />
            <Tooltip />
            <Legend />
            <Bar dataKey="Time" fill="#8676FE" />
            <Bar dataKey="Steps" fill="#FB7748" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="row mt-4">
        <div className="col-9">
          <h2 className="graphTitle">New Users</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={newUserData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="name" stroke="#8884d8" />
              <YAxis stroke="#8884d8" />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Users"
                stroke="#2B7FFF"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="col-3">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            <h2 className="top-performances-title">Top Performances</h2>
            {topPerformers.length > 0
              ? topPerformers.map((item, index) => {
                  let borderClass = "";
                  if (index === 0) borderClass = "gold-border";
                  else if (index === 1) borderClass = "silver-border";
                  else if (index === 2) borderClass = "bronze-border";

                  return (
                    <div
                      key={index}
                      className={`top-performance-card ${borderClass}`}
                    >
                      <span className="floating-label">
                        {item.fld_name || "No user"}
                      </span>
                      <span className="performance-medal">
                        {index < medals.length ? (
                          <img
                            src={medals[index].src}
                            alt={medals[index].alt}
                            style={{ width: "38px", height: "48px" }}
                          />
                        ) : null}
                      </span>
                      <div className="userDiv">
                        <span className="perf-userID">
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ color: "black", fontSize: "20px" }}
                          />
                        </span>
                        <span className="performance-name">
                          {item.fld_user_id || "No user"}
                        </span>
                      </div>
                      <div className="userDiv">
                        <span className="perf-userID">
                          <FontAwesomeIcon
                            icon={faShoePrints}
                            rotation={270}
                            style={{ color: "#0c0d0e", fontSize: "20px" }}
                          />
                        </span>
                        <span className="performance-name">
                          {item.count || "No steps"}
                        </span>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
